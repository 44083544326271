import dynamic from "next/dynamic";
import { ComponentProps } from "react";
import LoginModal from "./LoginModal";
import ResetPasswordModal from "./ResetPasswordModal";
import ConfirmEmailModal from "./ConfirmEmailModal";
import UnsubscribeSavedSearchesModal from "./UnsubscribeSavedSearchesModal";

export const DynamicLoginModal = dynamic<ComponentProps<typeof LoginModal>>(
  () => import("./LoginModal").then((module) => module.default)
);

export const DynamicResetPasswordModal = dynamic<
  ComponentProps<typeof ResetPasswordModal>
>(() => import("./ResetPasswordModal").then((module) => module.default));

export const DynamicConfirmEmailModal = dynamic<
  ComponentProps<typeof ConfirmEmailModal>
>(() => import("./ConfirmEmailModal").then((module) => module.default));

export const DynamicUnsubscribeSavedSearchesModal = dynamic<
  ComponentProps<typeof UnsubscribeSavedSearchesModal>
>(() =>
  import("./UnsubscribeSavedSearchesModal").then((module) => module.default)
);
