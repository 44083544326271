import { createContext, Dispatch, SetStateAction } from "react";
import { LoginModalMode } from "./types/LoginModalMode";

export type LoginModalContextState = {
  setShowModal(value: boolean): void;
  setDefaultEmailAddress?: (value: string) => void;
  setDefaultFirstName?: (value: string) => void;
  setDefaultLastName?: (value: string) => void;
  modeState: [LoginModalMode, Dispatch<SetStateAction<LoginModalMode>>];
};

const LoginModalContext = createContext<LoginModalContextState>(
  {} as LoginModalContextState
);

export default LoginModalContext;
