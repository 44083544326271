import { NextRouter } from "next/router";

export const removeQueryParams = (
  router: NextRouter,
  paramsToRemove: Array<string> = []
) => {
  const routerRef = router;

  if (paramsToRemove.length > 0) {
    paramsToRemove.forEach((param) => delete routerRef.query[param]);
  } else {
    // Remove all query parameters
    Object.keys(router.query).forEach((param) => delete routerRef.query[param]);
  }

  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    /**
     * Do not refresh the page when the query params are removed
     */
    { shallow: true }
  );
};

export const removeQueryParamsFromPathString = (
  asPath: string,
  paramsToRemove: Array<string> = []
) => {
  const asPathSplit = asPath.split("?");

  if (asPathSplit.length === 2) {
    const urlSearchParams = new URLSearchParams(asPathSplit[1]);

    paramsToRemove.forEach((param) => {
      urlSearchParams.delete(param);
    });

    const queryParams = urlSearchParams.toString();
    const q = queryParams.length === 0 ? "" : "?";
    return `${asPathSplit[0]}${q}${queryParams}`;
  }

  return asPath;
};
