import { TranslationKeys } from "src/@types/i18next";

export enum ModeName {
  login = "login",
  createAccount = "createaccount",
  emailConfirmed = "emailconfirmed",
  forgotPassword = "forgotPassword",
}

export type LoginModalMode = {
  name: ModeName;
  modalHeaderTextKey: TranslationKeys["common"];
};

export const loginMode: LoginModalMode = {
  name: ModeName.login,
  modalHeaderTextKey: "common:sign_in_modal_header",
};

export const createAccountMode: LoginModalMode = {
  name: ModeName.createAccount,
  modalHeaderTextKey: "common:create_account_modal_header",
};

export const forgotPasswordMode: LoginModalMode = {
  name: ModeName.forgotPassword,
  modalHeaderTextKey: "common:forgot_password",
};
